import { useState, useEffect, useRef } from "react";
import { baseserverURL } from "./ApiService";

// Custom hook to handle SSE with auto-reconnect
const useSSE = (clientId) => {
	console.log("useSSE render");

	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);
	const [isConnected, setIsConnected] = useState(false);
	const eventSourceRef = useRef(null); // Use a ref instead of state

	useEffect(() => {
		if (!clientId) return;

		// Prevent duplicate connections
		if (eventSourceRef.current) {
			console.log("SSE connection already exists for this client.");
			return;
		}

		const connectToSSE = () => {
			console.log("Attempting to connect to SSE for clientId:", clientId);
			const clientsUsername=localStorage.getItem("companyname")

			const es = new EventSource(`${baseserverURL}/events/${clientId}?name=${clientsUsername}`);

			es.onmessage = (event) => {
				const message = JSON.parse(event.data);
				if (message) {
					setMessage(message.message);
					localStorage.setItem("sseMessage", message.message);
					window.dispatchEvent(new Event("sseMessageChange"));
				}
			};

			es.onerror = (error) => {
				console.error("EventSource error:", error);
				setError(error);
				setIsConnected(false);
				es.close();
				eventSourceRef.current = null; // Reset ref to allow reconnect
				setTimeout(connectToSSE, 5000);
			};

			es.onopen = () => {
				console.log("SSE connection established.");
				setIsConnected(true);
			};

			es.onclose = () => {
				console.log("SSE connection closed.");
				setIsConnected(false);
				eventSourceRef.current = null; // Ensure reconnection can happen
			};

			eventSourceRef.current = es; // Store the connection
		};

		connectToSSE();

		// Cleanup on unmount or clientId change
		return () => {
			if (eventSourceRef.current) {
				eventSourceRef.current.close();
				eventSourceRef.current = null;
			}
		};
	}, [clientId]);

	// Reset connection manually
	const resetConnection = () => {
		if (eventSourceRef.current) {
			eventSourceRef.current.close();
			eventSourceRef.current = null;
		}
		setIsConnected(false);
		setMessage(null);
		setError(null);
	};

	return { message, error, isConnected, resetConnection };
};

export default useSSE;
