import { useEffect } from "react";
import axiosInstance from "../../services/axiosInstance";
import { versionLogURL } from "../../services/ApiService";

function useVersionChecker() {
	// Function to send version log to the backend
	const sendVersionLog = async (version) => {
		try {
			const appId = localStorage.getItem("customerid");
			if (!appId) {
				console.error("Missing customer ID");
				return;
			}

			await axiosInstance.post(versionLogURL, { id: appId, version });
			console.log("Version log sent:", { id: appId, version });
		} catch (error) {
			console.error("Failed to send version log", error);
		}
	};
	// Force cache clear and page refresh
	const refreshPage = () => {
		//window.location.href = window.location.href;
	
			window.location.reload(true); // Reloads the current page, ignoring the cache
	};
	const setCookieExpireSunday = (name, value) => {
		const today = new Date();
		const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

		// Calculate days until next Sunday
		const daysUntilSunday = dayOfWeek === 0 ? 7 : 7 - dayOfWeek;

		// Set expiration to next Sunday at midnight
		const expires = new Date();
		expires.setDate(today.getDate() + daysUntilSunday);
		expires.setHours(0, 0, 0, 0); // Set time to midnight

		localStorage.removeItem("historyorderstorage");
		localStorage.removeItem("updatedcartstorage");
		localStorage.removeItem("IpAdresa");
		//localStorage.removeItem("firmaemri");
		//localStorage.removeItem("id_dealersorders");
		//localStorage.removeItem("lastactivetime");
		//localStorage.removeItem("sseMessage");

		document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
	};

	const getCookie = (name) => {
		const cookies = document.cookie.split("; ");
		for (let cookie of cookies) {
			const [cookieName, cookieValue] = cookie.split("=");
			if (cookieName === name) return cookieValue;
		}
		return null;
	};

	useEffect(() => {
		const checkForUpdate = async () => {
			try {
				const appId = localStorage.getItem("customerid");
				if (!appId) {
					console.error("Skipping version check: No customer ID found.");
					return;
				}

				const response = await fetch("/version.json");
				const data = await response.json();

				const currentVersion = data.version;
				//const storedVersion = localStorage.getItem("appVersion");
				const storedVersion = getCookie("appVersion"); // Get version from cookie

				// Send version log only if the version has changed
				if (!storedVersion || storedVersion !== currentVersion) {
					await sendVersionLog(currentVersion);
					//	localStorage.setItem("appVersion", currentVersion);
					setCookieExpireSunday("appVersion", currentVersion, 7); // Store in cookie for 7 days
					console.log("Version changed, log sent.");
				}

				if (storedVersion && storedVersion !== currentVersion) {
					// Force cache clear and page refresh
					refreshPage();
					localStorage.clear()

					// Force a hard refresh by modifying the URL to bypass cache
					// window.location.href = window.location.pathname + "?v=" + new Date().getTime();
					// console.log("Refreshed because of new version");
				}
			} catch (error) {
				console.error("Version check failed", error);
			}
		};

		// Run immediately and set an interval for future checks
		checkForUpdate();
		const interval = setInterval(checkForUpdate, 60000 * 5); // Check every 5 minutes

		return () => clearInterval(interval);
	}, []);

	return null;
}

export default useVersionChecker;
