import SearchBar from "../searchbar/SearchBar";
import NavbarTop from "./navbar/Navbar";
import AppRoutes from "./routes/Routes";
import Topbar from "./topbar/TopBar";

function NavigationTop() {
	return (
		<>
			<Topbar />
			<SearchBar/>
			<NavbarTop />

		
		
		</>
	);
}

export default NavigationTop;
