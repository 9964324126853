import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export function useRemoveConfirmation() {
  const { t } = useTranslation();

  const showConfirmation = async () => {
    const result = await Swal.fire({
      title: t('cart.removeproduct'),       // Translation key for title
      text: t('cart.remove_prompt'),      // Translation key for text
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t('common.yes'),   // Translation key for "Yes"
      cancelButtonText: t('common.no'),     // Translation key for "No"
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    });

    return result.isConfirmed;
  };

  return showConfirmation;
}