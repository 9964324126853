import React, { useEffect, useRef, useState } from "react";
import "./ChatComponent.css";
import imagePaths from "../../../components/imagepaths"; // Update the path as necessary
import axios from "axios";
import { chatUrl } from "../../../services/ApiService"; // Update the path as necessary
import axiosInstance from "../../../services/axiosInstance";

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userId] = useState(+localStorage.getItem("customerid"));
  const [chatIcon, setChatIcon] = useState(imagePaths.chaticon);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const messagesEndRef = useRef(null);

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const timeoptions = {
    timeZone: "Europe/Paris",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  const scrollToBottom = () => {
    const chatMessages = document.querySelector('.chat-messages');
    if (chatMessages) {
      chatMessages.scrollTop = chatMessages.scrollHeight;
    }
  };
  

  const fetchMessages = async () => {
    const formattedDate = new Date().toLocaleDateString("de-DE", options);
    try {
      const response = await axiosInstance.get(`${chatUrl}/${userId}/1/${formattedDate}`);
      if (response.data && response.data.length > 0) {
        const latestMessages = response.data.map((msg) => ({
          id_customer: msg.id_customer,
          id_employee: msg.id_employee,
          text: msg.text,
          data: new Date(msg.data).toLocaleDateString("de-DE", options),
          ora: new Date(msg.ora).toLocaleTimeString("en-US", timeoptions),
          sent_by_customer: msg.sent_by_customer,
          sent_by_employee: msg.sent_by_employee,
        }));
        const lastStoredMessage = JSON.parse(localStorage.getItem("lastMessage")) || {};
        const lastFetchedMessage = latestMessages[latestMessages.length - 1];

        if (
          JSON.stringify(lastStoredMessage) !== JSON.stringify(lastFetchedMessage)
        ) {
          setIsNewMessage(true);
          setChatIcon(imagePaths.chaticonDanger);
          localStorage.setItem("recievedmessage", JSON.stringify(lastFetchedMessage));
         // localStorage.setItem("lastMessage", JSON.stringify(lastFetchedMessage));
          setMessages(latestMessages);
        } else {
          setIsNewMessage(false);
          setChatIcon(imagePaths.chaticon);
        }
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const currentTime = new Date().toLocaleTimeString("en-US", timeoptions);
      const formattedDate = new Date().toLocaleDateString("de-DE", options);
      const messagePayload = {
        id_customer: userId,
        id_employee: 1,
        text: newMessage,
        data: formattedDate,
        ora: currentTime,
        sent_by_customer: 1,
        sent_by_employee: 1,
      };
  
      try {
        const response = await axiosInstance.post(`${chatUrl}`, messagePayload);
        console.log("Message sent successfully:", response.data);
  
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            ...messagePayload,
            ora: currentTime,
            data: formattedDate,
            sent_by_customer: 1,
            sent_by_employee: 1,
          },
        ]);
  
        setNewMessage("");

        localStorage.setItem("recievedmessage", JSON.stringify(messagePayload));
  
        fetchMessages(); // Optionally refresh messages
      } catch (error) {
        console.error("Error sending message: ", error);
      }
    }
  };
  
  useEffect(() => {
    scrollToBottom(); // Scroll when messages change
    console.log('scroll to bottom mesage')
  }, [messages]);  // Automatically scroll when messages are updated
  

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of Enter key (such as form submission)
      handleSendMessage();
    }
  };

  useEffect(() => {
    fetchMessages();
    const intervalId = setInterval(fetchMessages, 30000); // Fetch messages every 30 seconds
    return () => clearInterval(intervalId);
  }, [userId]);

  return (
    <div className="chat-container">

      <div className="chat-header">Chat</div>
      <div className="chat-messages" >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${
              message.sent_by_customer ? "received" : "sent"
            }`}
          >
            <span className="message-text">{message.text}</span>
            <div className="message-metadata">
              {message.date} {message.ora}
            </div>
          </div>
        ))}
         <div ref={messagesEndRef} /> {/* Empty div at the bottom for scrolling */}
      </div>
      <div className="chat-footer">
        <input
          type="text"
          className="chat-input"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyPress} // Trigger on Enter key press
        />
        <button className="chat-send-button" onClick={handleSendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatComponent;
