import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../contexts/AuthContext";

const SignOutInactiveUser = () => {
    const { Logout } = useAuthContext();
    const [lastActiveTime, setLastActiveTime] = useState(() => {
        return Number(localStorage.getItem("lastActiveTime")) || Date.now();
    });

    useEffect(() => {
        const updateLastActiveTime = () => {
            const currentTime = Date.now();
            setLastActiveTime(currentTime);
            localStorage.setItem("lastActiveTime", currentTime);
        };

        window.addEventListener("click", updateLastActiveTime);
        window.addEventListener("keydown", updateLastActiveTime);

        return () => {
            window.removeEventListener("click", updateLastActiveTime);
            window.removeEventListener("keydown", updateLastActiveTime);
        };
    }, []);

    useEffect(() => {
        const signOutIfInactive = () => {
            const lastTime = Number(localStorage.getItem("lastActiveTime")) || Date.now();
            const currentTime = Date.now();
            const inactiveDuration = currentTime - lastTime;
            const twoHoursInMilliseconds = 60 * 60 * 1000; // 2 hours

            if (inactiveDuration > twoHoursInMilliseconds) {
                console.log("User inactive for 2+ hours. Logging out...");
                Logout();
                localStorage.clear();
            }
        };

        signOutIfInactive(); // Check immediately on mount
        const intervalId = setInterval(signOutIfInactive, 60000); // Check every 1 min

        return () => clearInterval(intervalId);
    }, []);

    return null;
};

export default SignOutInactiveUser;
