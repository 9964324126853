import React from "react";
import "./LeftColumn.css"; // Ensure you have any required styles

import { useCartContext } from "../../contexts/CartContext";
import { brandURL } from "../../services/ApiService";
import imagePaths from "../../components/imagepaths";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../services/axiosInstance";

function LeftColumn() {
	const { setResults } = useCartContext(); // Correct usage of useCartContext
	const [query, setQuery] = useState("");
	const reactappname = process.env.REACT_APP_APP_NAME;
	  const { t } = useTranslation();

	const kerkimi = query.toUpperCase();

	async function handleSearchBrandQuery(query) {
		const kerkimi = query.toUpperCase(); // Moved inside the function to ensure it uses the latest query
		try {
			const response = await axiosInstance.get(`${brandURL}${kerkimi}`);
			if (response.data.length === 0) {
				setResults([]);
			} else {
				const transformedResults = response.data.map((item) => ({
					id: item.id,
					oem_nr: item.upcsku,
					produkt_name: item.productname,
					vend_nr: item.vendoritemnumber,
					brand: item.brand,
					cmimi_pakice: item.salesprice,
					cmimi_shumice: item.salesstandardcost,
					pershkrimi: item.salesdescription,
					purchase_description: item.purchasedescription,
					kampanja: item.kampanja,
					cmim_kampanje: item.cmim_kampanje,
					data_kampanja: item.data_kampanja,
					sasia: item.sasia,
					sasia_1: item.sasia_1,
					sasia_2: item.sasia_2,
				}));
				setResults(transformedResults);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setResults([]);
		}
	}

	const handleImageClick = (brand) => {
		setQuery(brand);
		handleSearchBrandQuery(brand);
		console.log("query: ", brand);
	};

	return reactappname === "benfiko" ? (
		<div>
			<h5 className="uljetitulli">{t("oilsbenfiko")}</h5>
			<div className="brands-grid">
				<img
					src={imagePaths.aral}
					alt="aral"
					onClick={() => handleImageClick("ARAL")}
				/>
				<img
					src={imagePaths.castrol}
					alt="castrol"
					onClick={() => handleImageClick("castrol")}
				/>
				<img
					src={imagePaths.mobil}
					alt="mobil"
					onClick={() => handleImageClick("mobil")}
				/>
				<img
					src={imagePaths.shell}
					alt="shell"
					onClick={() => handleImageClick("shell")}
				/>
				<img
					src={imagePaths.mercedes}
					alt="mercedes"
					onClick={() => handleImageClick("oe mercedes")}
				/>
			</div>

		</div>
	) : (
		<div>
			<div className="uljetitulli">{t("oilsautostar")}</div>
			<div className="brands-grid">
				<img
					src={imagePaths.gmoil}
					alt="aral"
					onClick={() => handleImageClick("gm oil")}
				/>
				<img
					src={imagePaths.total}
					alt="total"
					onClick={() => handleImageClick("total")}
				/>
				<img
					src={imagePaths.motorcraft}
					alt="motorcraft"
					onClick={() => handleImageClick("motorcraft")}
				/>
								<img
					src={imagePaths.febilubricant}
					alt="febilubricant"
					onClick={() => handleImageClick("febi lubricant")}
				/>
							<img
					src={imagePaths.kraftvolllubricants}
					alt="kraftvolllubricant"
					onClick={() => handleImageClick("kraftvoll lubricant")}
				/>
				<img
					src={imagePaths.rhcleaner}
					alt="rhcleaner"
					onClick={() => handleImageClick("rh cleaner")}
				/>

	
				<img
					src={imagePaths.ravenol}
					alt="ravenol"
					onClick={() => handleImageClick("ravenol")}
				/>
				<img
					src={imagePaths.braxislubricants}
					alt="braxislubricants"
					onClick={() => handleImageClick("braxis lubricant")}
				/>
								<img
					src={imagePaths.boschlubricants}
					alt="boschlubricants"
					onClick={() => handleImageClick("bosch lubricants")}
				/>
			</div>

		</div>
	);
}

export default LeftColumn;
