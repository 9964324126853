// Assuming you have REACT_APP_APP_NAME set in your environment variables
//import io from 'socket.io-client';


const baseserver = process.env.REACT_APP_APP_NAME === 'benfiko'
  ? 'https://vps.benfiko.mk:3010'
  : process.env.REACT_APP_APP_NAME === 'autostar'
  ? 'https://vps.autostar.mk:3020'
  : 'https://localhost:3020';

 
//? 'https://interspace.freemyip.com:3010'
//  : 'https://interspace.freemyip.com:3020'
//-------------------serveri puna
//? 'https://tetnet.freemyip.com:3010'
//  : 'https://tetnet.freemyip.com:3020'
//Lokalisht
//? 'https://localhost:3020'
 //: 'https://vps.benfiko.mk:3010'
//Interspace vps


// eslint-disable-next-line
{/* ? 'https://185.226.0.16:3010'
: 'https://185.226.0.16:3020';*/}
console.log("baseserver: ",baseserver)
export const baseserverURL=baseserver;
export const baseURL = `${baseserver}/products/mytecdoc/`;
export const loginURL = `${baseserver}/customers/login/`;
export const inventoryURL = `${baseserver}/inventory/`;
export const initialCartURL = `${baseserver}/tempitems/`;
export const historyOrderURL = `${baseserver}/orders/`;
export const itemsDetailURL = `${baseserver}/items/`;
export const chatUrl = `${baseserver}/chat`;
export const dealerordersURL= `${baseserver}/orders`;
export const productimageURL= `${baseserver}/productproperties/`;
export const customersURL= `${baseserver}/customers/`;
export const productsNameSearchURL= `${baseserver}/productsname/`;
export const firmaURL=`${baseserver}/firma`;
export const brandURL=`${baseserver}/productsbrand/`;
export const kampanjaURL=`${baseserver}/kampanja/`
export const checkOrderURL=`${baseserver}/ongoingorder/`
//export const socket = io(`${baseserver}`); // Adjust the URL to match your server
export const versionLogURL=`${baseserver}/log-version/`
export const checkCartChange=`${baseserver}/ongoingorderchangedtrack/`

// export const webpageurl = 'https://tetnet-pro.com/benfikorder';
