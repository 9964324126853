import { useState } from "react";
import "./searchResults.css";
import { Button } from "react-bootstrap";
import { useCartContext } from "../../contexts/CartContext";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../services/axiosInstance";
import { productimageURL } from "../../services/ApiService";

function SearchResults() {
	const { handleAddToCartResult, results } = useCartContext();
	const [quantities, setQuantities] = useState({});
	const { setQuery } = useCartContext();
	const { t } = useTranslation();
	const [selectedImage, setSelectedImage] = useState(null);
	const emptyimage = "/image/results/image_search.png";
	const [isHovered, setIsHovered] = useState(false);

	const handleClick = (value) => {
		//const sanitizedValue = value.replace(/[^A-Za-z0-9]/gi, "");//.replace(/[ _:/-.]/g, ""); //[ _:/-.] zevendeso cfar don te hekesh
		const sanitizedValue = value.replace(/[^A-Za-z0-9\/.-]/gi, "");
		// console.log("Clicked value:", sanitizedValue);
		//let encodedQuery = encodeURIComponent(sanitizedValue);
		setQuery(sanitizedValue);
	};

	function BrandImageSources(brand) {
		return `./image/brandparts/${brand}.png`;
	}
	const reactappname = process.env.REACT_APP_APP_NAME;
	/*
	
	const nobrandimage = process.env.REACT_APP_APP_NAME === 'benfiko'
? "./image/brandparts/BENFIKO.png"
 : './image/brandparts/AUTOSTAR.png'
*/

	const buybuttonclassname =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? "benfikobuybuttonclass"
			: "autostarbuybuttonclass";

	const openModalImage = (oemNr, brand, id) => {
		axiosInstance
			.get(`${productimageURL}${id}`)
			.then((response) => {
				const foto = response.data.image;
				console.log("response.data", foto);
				if (foto !== undefined && foto !== "") {
					setSelectedImage(foto);
				} else {
					const searchUrl = `https://www.google.com/search?q=${oemNr} ${brand}&tbm=isch`;
					const confirmed = window.confirm(`${t("imagegoogle")}`);
					if (confirmed) {
						window.open(searchUrl, "_blank");
					}
				}
			})
			.catch((error) => {
				console.error("Error fetching image:", error);
			});
	};

	const closeModalImage = () => {
		setSelectedImage(null);
	};

	const increment = (index) => {
		const newQuantity = (quantities[index] || 1) + 1;
		setQuantities((prevQuantities) => ({
			...prevQuantities,
			[index]: newQuantity,
		}));
	};

	const decrement = (index) => {
		const newQuantity = Math.max(1, (quantities[index] || 1) - 1);
		setQuantities((prevQuantities) => ({
			...prevQuantities,
			[index]: newQuantity,
		}));
	};

	const handleChange = (index, value) => {
		if (value === "" || isNaN(value)) return;
		const numericValue = Math.max(1, parseInt(value));
		setQuantities((prevQuantities) => ({
			...prevQuantities,
			[index]: numericValue,
		}));
	};

	const handleBuyClick = (result, index) => {
		const quantityToBuy = quantities[index] || 1; // Get the latest quantity or default to 1
		handleAddToCartResult(result, quantityToBuy); // Send correct quantity to cart context
	};
	const getStatusSasia = (sasia, sasia1, sasia2) => {
		const reactappname = process.env.REACT_APP_APP_NAME;

		if (reactappname === "benfiko") {
			if (sasia > 10) {
				return (
					<div>
						<div className="objekti-number-group">
							{sasia1 > 0 && <div className="objekti-number">1</div>}
							{sasia2 > 0 && <div className="objekti-number">2</div>}
						</div>
					</div>
				);
			} else if (sasia >= 1 && sasia <= 10) {
				return (
					<div>
						<div className="objekti-number-group">
							{sasia1 > 0 && <div className="objekti-number">1</div>}
							{sasia2 > 0 && <div className="objekti-number">2</div>}
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div className="amountzero">___</div>
					</div>
				);
			}
		} else {
			return <div></div>;
		}
	};
	const warehouseimage = () => {
		if (reactappname === "benfiko") {
			return (
				<img
					src="./image/icons/warehouse.png"
					alt="warehouse"
					className="search-image-warehouse"
				/>
			);
		} else {
			return <></>;
		}
	};
	const getStatusSasiaTotal = (sasia) => {
		if (sasia > 10) {
			return <div className="search-sasia">{">10"}</div>;
		} else if (sasia >= 1 && sasia <= 10) {
			return <div className="search-sasia">{sasia}</div>;
		} else {
			return <div className="search-sasia">{"0"}</div>;
		}
	};
	return (
		<>
			{results.length > 0 ? (
				results.map((result, index) => (
					<div key={index} className="search-box-results">
						<div className="brand-image kolonat">
							<div className="search-brand">
								<img
									src={BrandImageSources(result.brand)}
									alt={result.brand}
									//onError={(e) =>(e.target.src =`${nobrandimage}`)}//) "./image/brandparts/NOBRAND.png")}
								/>
							</div>
							<div>
								<div className="emri-article-oem">
									<h5 className="article-name-searchbox">
										{result.produkt_name ||
											"ARAL SUPER TRONIC FULL SINTETIC 0/40 1/1"}
									</h5>
									<div className="oemnumberblock">
										<img
											className="searchoemgoogle"
											onClick={() =>
												openModalImage(result.oem_nr, result.brand, result.id)
											}
											style={{ cursor: "pointer" }}
											src={
												result.image
													? `data:image/jpeg;base64,${result.image}`
													: `${emptyimage}`
											}
											alt="empty"
										/>
										<div onClick={() => handleClick(result.oem_nr)}>
											{t("oemnumber")}: {result.oem_nr}
										</div>
									</div>
									<div className="oemnumberblock">
										<img
											className="searchoemgoogle"
											onClick={() =>
												openModalImage(result.vend_nr, result.brand, result.id)
											}
											style={{ cursor: "pointer" }}
											src={
												result.image
													? `data:image/jpeg;base64,${result.image}`
													: `${emptyimage}`
											}
											alt="empty"
										/>
										<div onClick={() => handleClick(result.vend_nr)}>
											{t("articlenumber")}: {result.vend_nr}
										</div>
									</div>
									<div className="pershkrimi">
										{t("articledescription")}: {result.pershkrimi}
									</div>
								</div>
							</div>
						</div>

						<div className="quantity-control kolonat">
							<div className="objekti-info">
								<div className="search-sasia">
									{getStatusSasiaTotal(result.sasia)}
								</div>
								<div className="search-objekti">
									{warehouseimage()}

									<div className="objekti-number-group">
										{getStatusSasia(
											result.sasia,
											result.sasia_1,
											result.sasia_2
										)}
									</div>
								</div>

								<Button
									className="button-minus"
									variant="outline-primary"
									onClick={() => decrement(index)}
									disabled={quantities[index] === 1}
								>
									-
								</Button>
								<input
									type="text"
									value={quantities[index] || 1}
									onChange={(e) => handleChange(index, e.target.value)}
									min="1"
									className="text-center"
									disabled={result.sasia <= 0} // Disable if sasia is 0
								/>
								<Button
									variant="outline-primary"
									onClick={() => increment(index)}
									disabled={result.sasia <= 0} // Disable if sasia is 0
								>
									+
								</Button>

								<button
									type="button"
									className={`btn btn-primary btn-sm search-buy-button ${buybuttonclassname}`}
									onClick={() => handleBuyClick(result, index)}
									disabled={result.sasia <= 0} // Disable if sasia is 0
								>
									{t("buy")}
								</button>
							</div>
							{/* Conditional price rendering */}
							{process.env.REACT_APP_APP_NAME === "benfiko" ? (
								<div className="cmimi">
									<span>{t("price")}: </span>
									<div className="searchresultprices">
										<span
											className="price"
											data-tooltip={`${t("dealerprice")}: ${
												result.cmimi_shumice || 2500
											}`}
										>
											{result.cmimi_pakice || 1500}
										</span>
									</div>
								</div>
							) : (
								<div className="cmimi">
									<span className="cmimipakice">{t("enduserprice")}: </span>
									<div className="searchresultprices">
										
										{result.cmimi_shumice || 1500}
										<br />
										{result.cmimi_pakice || 2500}
									</div>
								</div>
							)}

							{/* 
							<div className="cmimi">
								<span>{t("dealerprice")}: </span>
								<div className="searchresultprices">
									{result.cmimi_shumice || 1500}
									<br />
									{result.cmimi_pakice || 2500}
								</div>
							</div>
							*/}
							{/* 
							<div className="totali-search">
								{t("pricesum")}:{" "}
								{(quantities[index] || 1) * (result.cmimi_shumice || 1500)}
							</div>
							*/}
						</div>
					</div>
				))
			) : (
				<p>{t("noresults")}</p>
			)}
			{selectedImage && (
				<div className="rezultatet-modal-overlay" onClick={closeModalImage}>
					<div
						className="rezultatet-modal"
						onClick={(e) => e.stopPropagation()} // Prevent close on modal content click
					>
						<img
							src={`data:image/jpeg;base64,${selectedImage}`}
							alt="Preview"
							style={{ width: "900px", height: "700px" }}
						/>
						<button
							className="rezultatet-close-modal"
							onClick={closeModalImage}
						>
							Close
						</button>
					</div>
				</div>
			)}
		</>
	);
}

export default SearchResults;
