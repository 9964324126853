import React, { useState, useEffect } from "react";
import "./topfooter.css"; // Import the CSS file
import { checkCartChange } from "../../services/ApiService";
import axiosInstance from "../../services/axiosInstance";
import { t } from "i18next";

const TopFooter = () => {
	const [trackingOrderChanged, setTrackingOrderChanged] = useState(null);
	const [lastActiveTime, setLastActiveTime] = useState(
		localStorage.getItem("lastActiveTime")
	);
	const [dbConnectionLost, setDbConnectionLost] = useState(false);
	const [sseActive, setSseActive] = useState(false);

	// Function to fetch last order data
	const getLastOrder = async () => {
		const userId = localStorage.getItem("customerid"); // Get user ID dynamically
		if (!userId) return;

		try {
			const response = await axiosInstance.get(checkCartChange + userId);
			const lastOrderResponse = response.data.map((it) => ({
				oem_nr: it.upcsku,
				vend_nr: it.vendoritemnumber,
				emri: it.productname,
				quantity: it.quantity,
				quantity_sent: it.quantity_sent,
				id_dealersorders: it.id_dealersorders,
			}));

			setTrackingOrderChanged(
				lastOrderResponse.length ? lastOrderResponse : null
			);
			console.log("Tracking order updated");
		} catch (error) {
			console.error("Error fetching order data:", error);
			setTrackingOrderChanged(null);
		}
	};

	// Effect to check localStorage updates for order tracking
	useEffect(() => {
		const checkLastActiveTime = () => {
			const newLastActiveTime = localStorage.getItem("lastActiveTime");
			if (newLastActiveTime !== lastActiveTime) {
				setLastActiveTime(newLastActiveTime);
				getLastOrder();
			}
		};

		const interval = setInterval(checkLastActiveTime, 1000); // Check every second

		return () => clearInterval(interval); // Cleanup interval on unmount
	}, [lastActiveTime]);

	// Effect to monitor database connection status and fetch orders if lost

	useEffect(() => {
		const checkDatabaseConnection = () => {
			const sseMessage = localStorage.getItem("sseMessage");
			const isDbLost = sseMessage === "No database connection";

			if (isDbLost && !dbConnectionLost) {
				setDbConnectionLost(true);
				// Check if page has not been refreshed yet
				if (!sessionStorage.getItem("pageRefreshed")) {
					sessionStorage.setItem("pageRefreshed", "true"); // Mark as refreshed
					window.location.reload(); // Refresh the page
				}
			} else if (!isDbLost && dbConnectionLost) {
				setDbConnectionLost(false);
				sessionStorage.removeItem("pageRefreshed");
			}
		};

		checkDatabaseConnection(); // Run initially

		const interval = setInterval(checkDatabaseConnection, 1000); // Monitor sseMessage

		return () => clearInterval(interval); // Cleanup interval on unmount
	}, [dbConnectionLost]);
	//------------------disable input and buttons when db lost
	useEffect(() => {
		// List of classes to disable
		const classNames = [
			".search-input",
			".search-button",
			".nav-link",
			".navbartop-combined",
			".container",
		];

		classNames.forEach((selector) => {
			const elements = document.querySelectorAll(selector);
			elements.forEach((element) => {
				element.disabled = dbConnectionLost;

				// Disable pointer events and apply opacity when dbConnectionLost is true
				if (dbConnectionLost) {
					element.classList.add("opacity-50", "pointer-events-none");

					// Remove onclick event (and other event listeners)
					element.onclick = null; // Removes onclick handler

					// If you want to disable other event listeners, do this as well
					const allEventListeners = [
						"click",
						"keydown",
						"mousedown",
						"mouseup",
						"mouseenter",
						"mouseleave",
						"focus",
					];
					allEventListeners.forEach((event) => {
						element.removeEventListener(event, (e) => e.preventDefault());
					});
				} else {
					element.classList.remove("opacity-50", "pointer-events-none");

					// Restore onclick event if needed (example here)
					if (element.hasAttribute("data-original-onclick")) {
						element.onclick = element.getAttribute("data-original-onclick");
					}
				}
			});
		});
	}, [dbConnectionLost]); // Runs when dbConnectionLost changes

	// Effect to fetch orders every second if database connection is lost
	/*
useEffect(() => {
  if (!dbConnectionLost) return;

  const interval = setInterval(getLastOrder, 1000);

  return () => clearInterval(interval);
}, [dbConnectionLost]);

*/
	if (dbConnectionLost) {
		return (
			<footer className="top-footer-noconnection">
				<div className="top-footer-text-noconnection">
					<p className="scroll-text-noconnection">{t("connectionlost")}</p>
				</div>
			</footer>
		);
	}

	if (!trackingOrderChanged) {
		return null;
	}

	return (
<footer className="top-footer">
  <div className="top-footer-text">
    <div className="scroll-text-container">
      {trackingOrderChanged.map((item, index) => (
        <p className="product-item" key={index}>
 
          {t("oemnumber")}: {item.oem_nr}, 
          {t("articlenumber")}: {item.vend_nr}, 
          <span className="ordered-label"> {t("ordered")}</span>  
          <span className="ordered-value"> {item.quantity}</span>, 
          <span className="sent-label"> {t("sent")}</span>  
          <span className="sent-value"> {item.quantity_sent}</span> 
 
        </p>
      ))}
    </div>
  </div>
</footer>
	);
};

export default TopFooter;
