// CartUpdater.js
import axios from "axios";
import {
	itemsDetailURL,
	initialCartURL,
	dealerordersURL,
	historyOrderURL,
} from "../services/ApiService";
import axiosInstance from "../services/axiosInstance";
import { useCartContext } from "./CartContext";
import Swal from "sweetalert2";

export const fetchUpdatedCart = async (initialCartURL, customerid) => {
	//const customerid = localStorage.getItem("customerid");
	try {
		const urlHistory = `${initialCartURL}${customerid}/0`;
		const response = await axiosInstance.get(urlHistory);

		return response.data.map((item) => ({
			produkt_name: item.productname,
			oem_nr: item.upcsku,
			vend_nr: item.vendoritemnumber,
			emri: item.productname,
			id_product: item.id_product,
			cmimi_shumice: item.price,
			quantity: item.quantity,
			quantity_sent: item.quantity_sent || item.quantity,
			quantity_from_depot: item.quantity_from_depot || item.quantity,
			total: item.total,
			id_dealersorder: item.id_dealersorders,
			id: item.id,
		}));
	} catch (error) {
		console.error("Error fetching updated cart:", error);
		return [];
	}
};

export const removeItemAndUpdateCart = async (removedItemId) => {
	//const id_dealer = localStorage.getItem("customerid");
	//sendMessage(`"${id_dealer}"`, `"Delete item ID ${removedItemId}"`);
	console.log("item to be removed", removedItemId);
	try {
		const response = await axiosInstance.delete(
			`${itemsDetailURL}${removedItemId}`
		);
		console.log("Item deleted:", response.data);
		//	await sendMessage(`"${id_dealer}"`, `"Delete item ID ${removedItemId}"`);

		//const customerid = localStorage.getItem("customerid");
		//const updatedCart = await fetchUpdatedCart(initialCartURL, customerid);

		//localStorage.setItem("historycartstorage", JSON.stringify(updatedCart));
		//localStorage.setItem("updatedcartstorage", JSON.stringify(updatedCart));

		return response;

		// Send a message to the server
	} catch (error) {
		console.error("Error deleting item:", error);
		return null;
	}
};

export const addFirstDealerOrders = async (pagesaMenyra, dergesaMenyra) => {
    const dateSubmitted = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Paris",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    });

    const idDealer = localStorage.getItem("customerid");
    const dealerOrders = {
        datetimestarted: dateSubmitted,
        id_dealer: idDealer,
        accepted: 0,
        submitted: 0,
        menyra_pagesa: pagesaMenyra,
        menyra_dorezimi: dergesaMenyra,
    };

    try {
        const response = await axiosInstance.post(dealerordersURL, dealerOrders);
        if (!response || !response.data || !response.data.insertedId) {
            console.error("Error: Response is null or invalid");
            return null;
        }

        const iddealersorders = response.data.insertedId;
        localStorage.setItem("id_dealersorders", iddealersorders);
        return iddealersorders;
    } catch (error) {
        console.error("Error inserting dealer order:", error);
        return null;
    }
};

/*
export const addFirstItemAndUpdateCart = async (
	newCartItems,
	pagesaMenyra,
	dergesaMenyra
) => {
	const dateSubmitted = new Date().toLocaleString("en-US", {
		timeZone: "Europe/Paris",
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	});

	const idDealer = localStorage.getItem("customerid");
	const dealerOrders = {
		datetimestarted: dateSubmitted,
		id_dealer: idDealer,
		accepted: 0,
		submitted: 0,
		menyra_pagesa: pagesaMenyra,
		menyra_dorezimi: dergesaMenyra,
	};

	try {
		const response = await axiosInstance.post(dealerordersURL, dealerOrders);
		const iddealersorders = response.data.insertedId;
		localStorage.setItem("id_dealersorders", iddealersorders);

		const updatedCartItems = newCartItems.map((item) => ({
			id_dealersorders: iddealersorders,
			id_product: item.id_product,
			quantity: item.quantity || 0,
			quantity_from_depot: item.quantity || 0,
			price: item.cmimi_shumice || 0,
		}));

		const itemsResponse = await axiosInstance.post(
			itemsDetailURL,
			updatedCartItems
		);
		localStorage.setItem(
			"updatedcartstorage",
			JSON.stringify(updatedCartItems)
		);

		if (itemsResponse && itemsResponse.data) {
			return itemsResponse.data;
		} else {
			console.error("Invalid response from itemsDetailURL");
			return null;
		}
	} catch (error) {
		console.error("Error inserting dealer order or cart items:", error);
		return null;
	}
};
*/
//https://192.168.2.12:3020/orders/-1/2/0/-1/-1   //check for order id_dealer=2 ordersid and submitted=0

export async function getLastOrder(userId, setCartItems) {
	const urlHistory = initialCartURL + userId + "/" + 0;

	axiosInstance
		.get(urlHistory)
		.then(function (response) {
			const lastOrderResponse = response.data.map((it) => {
				return {
					produkt_name: it.productname,
					oem_nr: it.upcsku,
					vend_nr: it.vendoritemnumber,
					emri: it.productname,
					id_product: it.id_product,
					cmimi_shumice: it.price,
					quantity: it.quantity,
					quantity_sent: it.quantity_sent || it.quantity,
					quantity_from_depot: it.quantity_from_depot || it.quantity,
					total: it.total,
					id_dealersorders: it.id_dealersorders,
					id: it.id,
				};
			});

			setCartItems(lastOrderResponse); // Use setCartItems directly
			return lastOrderResponse;
		})
		.catch(function (error) {
			// handle error
			//console.log(error);
		})
		.finally(function () {
			// always executed
		});
}

export const updateItemAndUpdateCart = async (
	updatedItemId,
	updatedCartItem
) => {
	//const clientId = localStorage.getItem("customerid");
	try {
		const quantityToUpdate = {
			quantity: updatedCartItem,
			quantity_from_depot: updatedCartItem,
			quantity_sent: updatedCartItem,
		};
		console.log("updateItemAndUpdateCart ", quantityToUpdate);
		const url = `${itemsDetailURL}${updatedItemId}`;

		// Make a PATCH request with the quantityToUpdate data and clientId in the headers
		const response = await axiosInstance.patch(url, quantityToUpdate);
		return response;
	} catch (error) {
		console.error("Error details:", error);
		return null;
	}
};

export const addNewItemAndUpdateCart = async (updatedCartItem) => {
	const id_dealersorders = localStorage.getItem("id_dealersorders");
	//console.log("hyri tek funksioni addNewItemAndUpdateCart");
	//console.log("updated cart items tek addNewItem", updatedCartItem);
	const addNewItem = [updatedCartItem];
	if (!id_dealersorders) {
		console.error("id_dealersorders is not found in localStorage");
		return null;
	}

	try {
		const newAddedCartItems = addNewItem.map((item) => ({
			id_dealersorders: id_dealersorders,
			id_product: item.id_product,
			quantity: item.quantity,
			quantity_sent: item.quantity_sent || item.quantity,
			quantity_from_depot: item.quantity_from_depot || item.quantity,
			price: item.price,
		}));

		//	console.log("updated cart items tek newAddedCartItems", newAddedCartItems);
		const response = await axiosInstance.post(
			`${itemsDetailURL}`,
			newAddedCartItems
		);
		return response.data;
	} catch (error) {
		console.log("Error updating cart item:", error.response || error.message);
		return null;
	}
};

export const checkOutCart = async (pagesaMenyra, dergesaMenyra,shenime) => {
	const now = new Date();
	const dateSubmitted = `${String(now.getDate()).padStart(2, "0")}.${String(
		now.getMonth() + 1
	).padStart(2, "0")}.${now.getFullYear()} ${String(now.getHours()).padStart(
		2,
		"0"
	)}:${String(now.getMinutes()).padStart(2, "0")}`;
	console.log("datetimesubmitted: ", dateSubmitted);

	console.log("tek functioni derges: ", dergesaMenyra, "pagesa ", pagesaMenyra);

	const idDealerDealereOrder = localStorage.getItem("id_dealersorders");
	const dealerOrders = {
		id: idDealerDealereOrder,
		datetimesubmited: dateSubmitted,
		submitted: 1,
		menyra_pagesa: pagesaMenyra,
		menyra_dorezimi: dergesaMenyra,
		shenime:shenime,
	};

	console.log("url ", dealerordersURL);

	try {
		const response = await axiosInstance.patch(dealerordersURL, dealerOrders);
		localStorage.removeItem("id_dealersorders");
		localStorage.removeItem("id_dealersorders1");
		return response;
	} catch (error) {
		console.error("Error updating order:", error);
		return null;
	}
};

/*
export async function fetchLastOrder(userId) {
	const id_dealer = localStorage.getItem("customerid");
	//	sendMessage(`"${id_dealer}"`, "Get last item!");

	// Clean up when no longer needed

	const urlHistory = initialCartURL + userId + "/" + 0;
	//const {setCartItems} = useCartContext;
	//console.log("getLastOrder");
	axiosInstance
		.get(urlHistory)
		.then(function (response) {
			const lastOrderResponse = response.data.map((it) => {
				return {
					produkt_name: it.productname,
					oem_nr: it.upcsku,
					vend_nr: it.vendoritemnumber,
					emri: it.productname,
					id_product: it.id_product,
					cmimi_shumice: it.price,
					quantity: it.quantity,
					shuma: it.total,
					id_dealersorders: it.id_dealersorders,
					id: it.id,
				};
			});
	
			localStorage.setItem(
				"updatedcartstorage",
				JSON.stringify(lastOrderResponse)
			);
			//setCartItems(				JSON.parse(localStorage.getItem("updatedcartstorage")));
			//console.log("cartitems ", cartItems);

			//setCartItems(lastOrderResponse); // Use setCartItems directly
			return lastOrderResponse;
		})
		.catch(function (error) {
			// handle error
			console.log(error);
		})
		.finally(function () {
			// always executed
		});
}
*/
