import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { historyOrderURL, itemsDetailURL } from "../services/ApiService";
import "./css/history.css"; // Import CSS file for styles
import axiosInstance from "../services/axiosInstance";

const History = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const userid = localStorage.getItem("customerid");

	const currentDate = new Date();
	const initialMonth = currentDate.getMonth() + 1;
	const initialYear = currentDate.getFullYear();

	const [month, setMonth] = useState(initialMonth);
	const [year, setYear] = useState(initialYear);
	const [historyorder, setHistoryOrder] = useState(
		JSON.parse(localStorage.getItem("historyorderstorage")) || []
	);
	const [orderDetails, setOrderDetails] = useState([]);
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	const [isDetailTableOpen, setIsDetailTableOpen] = useState(false);
	const detailTableRef = useRef(null);

	// Calendar dropdown filter state and ref
	const [filterDay, setFilterDay] = useState("all");
	const [isCalendarDropdownOpen, setIsCalendarDropdownOpen] = useState(false);
	const calendarDropdownRef = useRef(null);

	const handleMonthChange = (month) => {
		setMonth(month);
		setFilterDay("all"); // Clear day filter
	};
	const handleYearChange = (event) => {
		setYear(event.target.value);
		setFilterDay("all"); // Clear day filter
	};

	useEffect(() => {
		localStorage.setItem("historyorderstorage", JSON.stringify(historyorder));
	}, [historyorder]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Close detail table if click outside
			if (
				detailTableRef.current &&
				!detailTableRef.current.contains(event.target)
			) {
				setIsDetailTableOpen(false);
			}
			// Close calendar dropdown if click outside
			if (
				calendarDropdownRef.current &&
				!calendarDropdownRef.current.contains(event.target)
			) {
				setIsCalendarDropdownOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	const fetchHistoryOrders = async (month) => {
		setIsLoading(true);
		const urlOrderHistory = `${historyOrderURL}-1/${userid}/1/+${year}/${month}`;
		try {
			const response = await axiosInstance.get(urlOrderHistory);
			const formattedOrders = response.data.map((it) => {
				const date = new Date(it.datetimesubmited).toLocaleDateString("en-GB", {
					day: "2-digit",
					month: "2-digit",
					year: "numeric",
				});
				return { ...it, datetimesubmited: date };
			});
			setHistoryOrder(formattedOrders);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchOrderDetails = async (orderId, index) => {
		const DetailURL = `${itemsDetailURL}${orderId}`;
		setSelectedRowIndex(index);
		try {
			const response = await axiosInstance.get(DetailURL);
			const formattedDetails = response.data.map((it) => ({
				productname: it.productname,
				oem_nr: it.upcsku,
				vendor_nr: it.vendoritemnumber,
				price: it.price,
				quantity: it.quantity,
				quantity_sent: it.quantity_sent,
				quantity_due: it.quantity_due,
				total: it.total,
				last_price: it.last_price,
				total_to_pay: it.total_to_pay,
				operator: it.opr_name,
			}));
			setOrderDetails(formattedDetails);
			setIsDetailTableOpen(true);
		} catch (error) {
			console.error(error);
		}
	};

	const filteredOrders = historyorder.filter((order) => {
		if (filterDay === "all") return true;
		const orderDay = parseInt(order.datetimesubmited.split("/")[0], 10);
		return orderDay === parseInt(filterDay, 10);
	});

	// Determine the number of days in the selected month/year
	const daysInMonth = month ? new Date(year, month, 0).getDate() : 31;
	const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

	const getSelectedDateLabel = () => {
		let label = "";
	
		if (filterDay !== "all") {
			label += `${filterDay.toString().padStart(2, "0")}.`; // Day (formatted as 2 digits)
		}
	
		if (month) {
			label += `${month.toString().padStart(2, "0")}.`; // Month (formatted as 2 digits)
		}
	
		label += year; // Append Year
	
		return label; // Example output: "08-02-2025"
	};
	

	return (
		<div className="history-container">
			{isLoading ? (
				<div className="spinner-border text-primary" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			) : (
				<div>
					<div className="filters-container mb-3">
						<div className="year-input">
							<input
								className="form-control"
								type="text"
								placeholder="Enter Year"
								value={year}
								onChange={handleYearChange}
							/>
						</div>
						<div className="months-container">
							<button
								className={`btn btn-sm me-2 ${
									month === null ? "btn-primary" : "btn-outline-primary"
								}`}
								onClick={() => {
									handleMonthChange(null);
									fetchHistoryOrders(null); // Fetch history without month value
								}}
							>
								{t("year")}
							</button>

							{Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
								<button
									key={m}
									className={`btn btn-sm me-2 ${
										month === m ? "btn-primary" : "btn-outline-primary"
									}`}
									onClick={() => {
										handleMonthChange(m);
										fetchHistoryOrders(m);
									}}
								>
									{m}
								</button>
							))}
						</div>
					</div>
					<div className="tables-container">
						<div className="primary-table">
							<h3 className="mb-3">{t("orders")} {getSelectedDateLabel()}</h3>
							<table className="table table-hover table-bordered">
								<thead className="thead-light">
									<tr>
										<th>{t("orderid")}</th>
										<th className="date-header"> {t("date")}
											<button
												type="button"
												className="histordaybutton btn btn-sm btn-outline-primary calendar-button"
												onClick={() =>
													setIsCalendarDropdownOpen(!isCalendarDropdownOpen)
												}
											>
												{filterDay === "all" ? t("AllDays") : filterDay}
											</button>
											{isCalendarDropdownOpen && (
												<div
													ref={calendarDropdownRef}
													className="calendar-dropdown"
												>
													<div className="calendar-grid">
														{daysArray.map((day) => (
															<div
																key={day}
																onClick={() => {
																	setFilterDay(day);
																	setIsCalendarDropdownOpen(false);
																}}
																className="calendar-day"
															>
																{day}
															</div>
														))}
													</div>
												</div>
											)}
										</th>

										<th>{t("paymentmethod")}</th>
										<th>{t("deliverymethod")}</th>
										<th>{t("totalsuma")}</th>
									</tr>
								</thead>
								<tbody>
									{filteredOrders.map((order, index) => (
										<tr
											key={index}
											className={
												selectedRowIndex === index ? "table-active" : ""
											}
											onClick={() => fetchOrderDetails(order.id, index)}
											style={{ cursor: "pointer" }}
										>
											<td>{order.id}</td>
											<td>{order.datetimesubmited}</td>
											<td>{order.menyra_pagesa}</td>
											<td>{order.menyra_dorezimi}</td>
											<td>{order.shuma}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						{isDetailTableOpen && (
							<div className="detail-table" ref={detailTableRef}>
								<h3 className="mb-3">Order Details</h3>
								{orderDetails.length > 0 ? (
									<table className="table table-striped table-bordered">
										<thead className="thead-dark">
											<tr>
												<th>{t("produktname")}</th>
												<th>{t("oemnumber")}</th>
												<th>{t("articlenumber")}</th>
												<th>{t("price")}</th>
												<th>{t("quantity")}</th>
												<th>{t("pricesum")}</th>
												<th>{t("QuantitySent")}</th>
												<th>{t("lastprice")}</th>
												<th>{t("totaltopay")}</th>
												<th>{t("QuantityDue")}</th>
												<th>{t("operator")}</th>
											</tr>
										</thead>
										<tbody>
											{orderDetails.map((detail, index) => (
												<tr key={index}>
													<td>{detail.productname}</td>
													<td>{detail.oem_nr}</td>
													<td>{detail.vendor_nr}</td>
													<td>{detail.price}</td>
													<td>{detail.quantity}</td>
													<td>{detail.total}</td>
													<td>{detail.quantity_sent}</td>
													<td>{detail.last_price}</td>
													<td>{detail.total_to_pay}</td>
													<td>{detail.quantity_due}</td>
													<td>{detail.operator}</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<p className="text-muted">No details available</p>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default History;
