import React from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import App from "../../App";
//import CheckTokenChange from "../../services/CheckTokenChange";
import SignIn from '../../pages/SignIn';
import TopFooter from "../../navigation/topbar/topFooter";

function LoginCheck() {
  const { loggedIn } = useAuthContext();
  //localStorage.clear;
 // console.log("Environment Variables:");
 // console.log("MODE_ENV:", process.env.REACT_APP_MODE_ENV);
  return <div>{loggedIn ? <App /> : <SignIn/>}</div>;
}

export default LoginCheck;
