import axios from "axios";
//import { baseserver } from "./ApiService";

// Create an Axios instance
const axiosInstance = axios.create({
 // baseURL: baseserver, // Use env variable as fallback
  timeout: 10000, // Set timeout to 10 seconds
});

// Cache clientId for optimization
//let cachedClientId = localStorage.getItem("customerid");

// Add request interceptor to attach headers
axiosInstance.interceptors.request.use(
  (config) => {
   // Get client ID from localStorage at request time
   const clientId = localStorage.getItem("customerid");
   if (clientId) {
     config.headers["x-client-id"] = clientId;
   } else {
     console.warn("Client ID is missing.");
   }

    // Add token to headers
    const token = localStorage.getItem("token"); // Replace with your token key
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    } else {
      console.warn("Authorization token is missing.");
    }

    return config;
  },
  (error) => {
    console.error("Axios request error:", error.message);
    return Promise.reject(error);
  }
);

// Retry mechanism
axiosInstance.interceptors.response.use(
  (response) => response, // Pass successful responses
  async (error) => {
    const { config } = error;

    // If config is undefined or retries are exhausted, reject
    if (!config || config.retryCount === undefined) {
      return Promise.reject(error);
    }

    // Set a default retry count if not already set
    config.retryCount = config.retryCount || 3;

    // Retry logic for timeout errors
    if (error.code === "ECONNABORTED" || error.message.includes("timeout")) {
      if (config.retryCount > 0) {
        console.warn(`Retrying request... Attempts left: ${config.retryCount}`);
        config.retryCount -= 1;

        // Delay before retrying (optional)
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay

        return axiosInstance(config); // Retry the request
      }
    }

    console.error("Axios response error:", error.message);
    return Promise.reject(error); // Reject after retries are exhausted
  }
);

export default axiosInstance;
