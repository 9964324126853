import React from "react";
import  "./css/home.css";
import SearchResults from "./center/SearchResults";
import LeftColumn from "./left/LeftColumn";
import RightColumn from "./right/RightColumn";

function HomePage() {
    return (

        <div className="container-home">
            <div className="left-column">
                <LeftColumn/>
               </div>
            <div className="middle-column">
                <SearchResults />
            </div>
            <div className="right-column"><RightColumn/></div>
        </div>
    );
}

export default HomePage;
