import React from "react";
import { Container, Navbar } from "react-bootstrap";
import "./TopBar.css";
import LanguageBar from "../languagebar/LanguageBar";
import IconBar from "../righticonbar/IconBar";

function Topbar() {
	const logotop =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? "/image/logo/benfikoLogoBlack.png"
			: "/image/logo/autostarLogoBlack.png";

	const topcllasname =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? "benfikotopnavigation"
			: "autostartopnavigation";

	const topnavbarclassname =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? "benfikotopnavbar"
			: "autostartopnavbar";

	console.log("logotop: ", logotop);
	//const logotop = process.env.PUBLIC_URL + process.env.REACT_APP_LOGOTOP;
	return (
		<Navbar className={topnavbarclassname} expand="lg">
			{/* Use Container-fluid for full width */}
			<Container fluid className={topcllasname}>
				{/* Navbar Brand on the left */}
				<Navbar.Brand href="#">
					<img
						src={logotop} //"/image/logo/benfikoLogoBlack.png"
						alt="Logo"
						style={{
							width: "124px",

							marginLeft: "5px", // Optional: set margin for consistency
						}}
					/>
				</Navbar.Brand>

				{/* LanguageBar in the center */}
				<div className="mx-auto">
					<LanguageBar />
				</div>

				{/* IconBar on the right */}
				<div>
					<IconBar />
				</div>
			</Container>
		</Navbar>
	);
}

export default Topbar;
