import { Button, Container } from "react-bootstrap";
import AppRoutes from "../routes/Routes";
import "./navbar.css"


function NavbarTop() {
	return (

			<div className="navbartop-combined"><AppRoutes /></div>

	);
}

export default NavbarTop;
