import React, { useEffect, useState } from "react";
import { useCartContext } from "../contexts/CartContext";
import "./css/shopingCartPage.css";
import {
	checkOutCart,
	updateItemAndUpdateCart,
} from "../contexts/CartContextFunctions";
import { useTranslation } from "react-i18next";
import { colors } from "@mui/material";

function ShopingCartPage() {
	const [shenime, setShenime] = useState("");
	const { t } = useTranslation();
	const {
		cartItems,
		handleRemoveItem,
		totalShuma,
		pagesaMenyra,
		dergesaMenyra,
		setPagesaMenyra,
		setDergesaMenyra,
	} = useCartContext();

	// Function to increment item quantity in the cart
	const handleIncrementQuantityCart = (index) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity += 1;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
		const idToUpdate = updatedCart[index].id;
		const quantityToUpdate = updatedCart[index].quantity;
		updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
	};

	// Function to decrement item quantity in the cart
	const handleDecrementQuantityCart = (index) => {
		const updatedCart = [...cartItems];
		if (updatedCart[index].quantity === 1) return;
		updatedCart[index].quantity -= 1;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
		const idToUpdate = updatedCart[index].id;
		const quantityToUpdate = updatedCart[index].quantity;
		updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
	};

	// Function to manually update item quantity in the cart
const handleAddManualQuantityCart = async (index, quantity) => { // Add async
  const parsedQuantity = parseInt(quantity, 10);

  if (parsedQuantity === 0) {
    // Trigger the existing removal function
    await handleRemoveItem(index); // Call the remove function
  } else {
    // Existing logic for valid quantities
    const newQuantity = isNaN(parsedQuantity) || parsedQuantity < 1 ? 1 : parsedQuantity;
    const updatedCart = [...cartItems];
    updatedCart[index].quantity = newQuantity;
    updatedCart[index].shuma = updatedCart[index].cmimi_shumice * newQuantity;
   // setCartItems(updatedCart);
    updateItemAndUpdateCart(updatedCart[index].id, newQuantity);
  }
};

	// Function to proceed to checkout
	const checkOut = () => {
		checkOutCart(pagesaMenyra, dergesaMenyra, shenime);
	};

	function BrandImageSources(produkt_name_brand) {
		const match = produkt_name_brand.match(/\((.*?)\)/);
		const brand = match ? match[1] : "";
		return brand ? `./image/brandparts/${brand}.png` : "";
	}

	return (
		<div className="fullpage-cart-container-page">
			<header className="cart-header-page">
				<h1>{t("yourshopingcart")}</h1>
			</header>
			<main className="cart-content-page">
				<div className="cart-layout-page">
					{/* Cart Items List */}
					<section className="cart-items-section-page">
						{cartItems.length === 0 ? (
							<div className="empty-cart-message-page">
								<p>{t("yourcartempty")}</p>
							</div>
						) : (
							<>
								{/*<h2>{t("cartitems")}</h2>}*/}
								<div className="cart-items-list-page">
									{cartItems.map((item, index) => (
										<div key={index} className="cart-item-page">
											<div className="cart-item-info-page">
												<div className="cart-page-infoja-brandi">
													<img
														className="brand-cart-page"
														src={BrandImageSources(item.produkt_name)}
														alt={item.brand}
														//onError={(e) =>(e.target.src =`${nobrandimage}`)}//) "./image/brandparts/NOBRAND.png")}
													/>
												</div>
												<div className="cart-page-oem-cmimi">
													<h5>{item.produkt_name}</h5>
													<p className="cartitemoemnr-page">
														{t("oemnumber")}: {item.oem_nr}
													</p>
													<p className="cartitemoemnr-page">
														{t("articlenumber")}: {item.vend_nr}
													</p>
													<p>
														<strong>{t("priceforone")}:</strong> {item.price}
													</p>
												</div>
											</div>
											<div className="cart-item-actions-page">
												<div className="quantity-control-page">
													{/*
													<button
														onClick={() => handleDecrementQuantityCart(index)}
														disabled={item.quantity <= 1}
														className="quantity-btn-page"
													>
														-
													</button>
													*/}
													<input
														type="text"
														value={item.quantity}
														onChange={(e) =>
															handleAddManualQuantityCart(index, e.target.value)
														}
														className="quantity-input-page"
													/>
													{/*
													<button
														onClick={() => handleIncrementQuantityCart(index)}
														className="quantity-btn-page"
													>
														+
													</button>
													*/}
												</div>
												<p className="cart-item-total-page">
													<strong>{t("pricesum")}:</strong> {item.total}
												</p>
												<button
													onClick={() => handleRemoveItem(index)}
													className="remove-btn-page"
												>
													X
												
												</button>
											</div>
										</div>
									))}
								</div>
							</>
						)}
					</section>
					{/* Checkout Details */}
					<aside className="checkout-details-section-page">
						<h2>{t("checkoutdetails")}</h2>
						<div className="method-card-page">
							<h5>{t("paymentmethod")}</h5>
							<select
								value={pagesaMenyra}
								onChange={(e) => setPagesaMenyra(e.target.value)}
								className="method-select-page"
							>
								<option value="Кеш">{t("cash")}</option>
								<option value="Фактура">{t("invoice")}</option>
								<option value="Со откуп">{t("sootkup")}</option>
							</select>
						</div>
						<div className="method-card-page">
							<h5>{t("deliverymethod")}</h5>
							<select
								value={dergesaMenyra}
								onChange={(e) => setDergesaMenyra(e.target.value)}
								className="method-select-page"
							>
								<option value="Самоподигање">{t("pickup")}</option>
								<option value="Такси / Комби">{t("taksikombi")}</option>
								<option value="Бус">{t("bus")}</option>
								<option value="Достава">{t("delivery")}</option>
								<option value="Карго">{t("cargo")}</option>
							</select>
						</div>
						<a1>{t("note")}:</a1>
						<textarea
							className="shenime_cart_page"
							type="text"
							value={shenime}
							onChange={(e) => setShenime(e.target.value)}
							rows={4} // Adjust number of visible rows
							style={{ wordWrap: "break-word", width: "100%" }}
						/>
						<div className="checkout-section-page">
							<h5 className="total-price-page"
	
							>
								{t("totalsuma")}: {totalShuma}
								
							</h5>
							<button onClick={checkOut} className="checkout-btn-page">
								{t("checkout")}
							</button>
						</div>
					</aside>
				</div>
			</main>
		</div>
	);
}

export default ShopingCartPage;
