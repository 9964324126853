import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

function LanguageBar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (

      <>
                {/* Spanish Flag */}
                <Button variant="link" onClick={() => changeLanguage('mk')}>
              <img
                  src="./image/flags/nmk.png"
                  alt="Macedonian"
                  width="30"
                  height="20"
                  style={{ marginRight: '10px' }} />
          </Button>

          {/* Albanian Flag (assuming 'al' is for Albanian) */}
          <Button variant="link" onClick={() => changeLanguage('al')}>
              <img
                  src="./image/flags/al.png"
                  alt="Albanian"
                  width="30"
                  height="20"
                  style={{ marginRight: '10px' }} />
          </Button>
          {/* English Flag */}
          <Button variant="link" onClick={() => changeLanguage('en')}>
              <img
                  src="./image/flags/us.png"
                  alt="English"
                  width="30"
                  height="20"
                  style={{ marginRight: '10px' }} />
          </Button>


     </>

  );
}

export default LanguageBar;
