import { nanoid } from "nanoid";
 import Cookies from "js-cookie";

export const getPersistentID = () => {
    let uniqueID = Cookies.get("pcUniqueId");

    if (!uniqueID) {
        uniqueID = nanoid(8); // Generate a short, 8-character ID
        Cookies.set("pcUniqueId", uniqueID, { expires: 365, secure: true, sameSite: "Strict" }); // Store for 1 year
    }

    return uniqueID;
};
// import FingerprintJS from 'fingerprintjs2';
// import Cookies from 'js-cookie';

// export const getPersistentID = async () => {
//     let uniqueID = Cookies.get("pcUniqueId");

//     if (!uniqueID) {
//         const components = await FingerprintJS.getPromise();
//         const fingerprint = components.map(({ value }) => value).join('');
//         uniqueID = btoa(fingerprint).substring(0, 7); // Encode and shorten the fingerprint

//         Cookies.set("pcUniqueId", uniqueID, { expires: 365, secure: true, sameSite: "Strict" });
//     }

//     return uniqueID;
// };

{/*
import { getFingerprintData } from '@thumbmarkjs/thumbmarkjs'
import Cookies from 'js-cookie';

export const getPersistentID = async () => {
    let uniqueID = Cookies.get("pcUniqueId");

    if (!uniqueID) {
        try {
            // Generate the fingerprint using thumbmarkjs
            const fingerprint = await getFingerprintData.get();

            // Extract the webgl.commonImageHash or any other unique value
            const webglHash = fingerprint.webgl.commonImageHash;

            // Encode and shorten the fingerprint (optional)
            uniqueID = btoa(webglHash).substring(0, 7);

            // Store the uniqueID in a cookie
            Cookies.set("pcUniqueId", uniqueID, { expires: 365, secure: true, sameSite: "Strict" });
        } catch (error) {
            console.error('Error generating fingerprint:', error);
            throw error;
        }
    }

    return uniqueID;
};*/}