import React, { useState, useEffect } from "react";
import "../css/footer.css"; // Import the CSS file
import { t } from "i18next";
import i18next from "i18next";

const Footer = () => {
  const isBenfiko = process.env.REACT_APP_APP_NAME === "benfiko";
 // const isBenfiko = process.env.REACT_APP_APP_NAME === "autopart";
  const [showAlternateText, setShowAlternateText] = useState(isBenfiko);

  const [languageChanged, setLanguageChanged] = useState(false);

  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguageChanged((prev) => !prev); // Force re-render on language change
    };

    i18next.on("languageChanged", handleLanguageChange);
    return () => i18next.off("languageChanged", handleLanguageChange);
  }, []);

  useEffect(() => {
    if (isBenfiko) {
      const timer = setTimeout(() => {
        setShowAlternateText(true);
      }, 10000); // 10 seconds delay

      return () => clearTimeout(timer);
    }
  }, [isBenfiko]);

  return (
    <footer className="footerbottom">
      {showAlternateText
        ? t("hoveroverprice")
        :(<p>
        © {new Date().getFullYear()}{" "}
        <a href="https://www.tetnet-pro.com" target="_blank" rel="noopener noreferrer">
          TetnetPro Computers
        </a>. All rights reserved.
      </p>)}
    </footer>
  );
};

export default Footer;
