import React, { useEffect } from "react";
import { useCartContext } from "../../../contexts/CartContext";
import "./ShopingCart.css";
import {
	checkOutCart,
	getLastOrder,
	updateItemAndUpdateCart,
} from "../../../contexts/CartContextFunctions";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function Cart() {
	const [shenime, setShenime] = useState("");
	const { t } = useTranslation();
	const {
		cartItems,
		handleRemoveItem,
		totalShuma,
		setCartItems,
		pagesaMenyra,
		dergesaMenyra,
		setPagesaMenyra,
		setDergesaMenyra,
	} = useCartContext();

	const handleIncrementQuantityCart = (index) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity += 1;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
		//  const quantity=updatedCart[index].quantity
		const idToUpdate = updatedCart[index].id;
		const quantityToUpdate = updatedCart[index].quantity;
		//setCartItems(updatedCart);
		updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
		console.log(
			`"handleIncrementQuantity ID: ", ${updatedCart[index].id} quantity: ${updatedCart[index].quantity}`
		);
		console.log("response: ", updateItemAndUpdateCart);
	};
	const handleDecrementQuantityCart = (index) => {
		const updatedCart = [...cartItems];

		// Prevent quantity from going below 1
		if (updatedCart[index].quantity === 1) {
			console.log("Quantity cannot be less than 1");
			return; // Exit the function early
		}

		updatedCart[index].quantity -= 1;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;

		if (updatedCart[index].quantity === 0) {
			updatedCart.splice(index, 1);
		}
		const idToUpdate = updatedCart[index].id;
		const quantityToUpdate = updatedCart[index].quantity;
		console.log("shoping cart quantity: ", updatedCart);

		//setCartItems(updatedCart);
		updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
		console.log(
			`"handleDecrementQuantity ID: ", ${updatedCart[index].id} quantity: ${updatedCart[index].quantity}`
		);
	};

	const handleAddManualQuantityCart = (index, quantity) => {
		const updatedCart = [...cartItems];
		const newQuantity = Math.max(parseInt(quantity, 10) || 1, 1); // Ensures minimum value is 1

		updatedCart[index].quantity = newQuantity;
		updatedCart[index].shuma = updatedCart[index].cmimi_shumice * newQuantity;

		const idToUpdate = updatedCart[index].id;
		const quantityToUpdate = updatedCart[index].quantity;

		console.log("updated cart Manual Shopping", updatedCart);
		updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
		console.log("handleAddManualQuantity ID: ", updatedCart[index].id);
	};
	const checkOut = () => {
		console.log(
			"pagesa menyra: ",
			pagesaMenyra,
			"dergesa Menyra: ",
			dergesaMenyra
		);
		checkOutCart(pagesaMenyra, dergesaMenyra, shenime);
	};

	useEffect(() => {
		// const totalShumaNdryshe=totalShuma
		//console.log('totalshumandryshe shopingcart', totalShumaNdryshe)
		//getLastOrder(checkUserId,setCartItems);
		// You can use this effect to update localStorage or perform any side effects when cart changes.
	}, []);

	return (
		<div className="cart-container">
			<h3 className="cart-title">{t("yourshopingcart")}</h3>
			{cartItems.length === 0 ? (
				<p className="cart-empty-message">{t("yourcartempty")}</p>
			) : (
				<>
					{/* Cart Items List */}
					<div className="cart-items-list">
						{cartItems.map((item, index) => (
							<div key={index} className="cart-item">
								<div className="cart-item-info">
									<h5>{item.produkt_name}</h5>
									<p className="cartitemoemnr">
										{t("oemnumber")}: {item.oem_nr}
									</p>
									<p className="cartitemoemnr">
										{t("articlenumber")}: {item.vend_nr}
									</p>
									<p>
										<strong>{t("priceforone")}:</strong> {item.price}
									</p>
								</div>
								<div className="cart-item-actions">
									<div className="quantity-control-shoping-cart">
										{/** 
										<button
											onClick={() => handleDecrementQuantityCart(index)}
											disabled={item.quantity <= 1}
											className="quantity-btn"
										>
											-
										</button>
										*/}
										<input
											type="text"
											value={item.quantity}
											onChange={(e) =>
												handleAddManualQuantityCart(index, e.target.value)
											}
											className="quantity-input"
										/>
										{/** 
										<button
											onClick={() => handleIncrementQuantityCart(index)}
											className="quantity-btn"
										>
											+
										</button>
										*/}
									</div>
									<p className="cart-item-total">
										<strong>{t("pricesum")}:</strong> {item.total}
									</p>
									<button
										onClick={() => handleRemoveItem(index)}
										className="remove-btn"
									>
										{t("remove")}
									</button>
								</div>
							</div>
						))}
					</div>
					<input
					className="shenime_cart"
						type="text"
						value={shenime}
						onChange={(e) => setShenime(e.target.value)}
					/>

					{/* Payment and Delivery Methods */}
					<div className="cart-methods">
						<div className="method-card">
							<h5>{t("paymentmethod")}</h5>
							<select
								value={pagesaMenyra}
								onChange={(e) => setPagesaMenyra(e.target.value)}
								className="method-select"
							>
								<option value="Кеш">{t("cash")}</option>
								<option value="Фактура">{t("invoice")}</option>
								<option value="Со откуп">{t("sootkup")}</option>
							</select>
						</div>

						<div className="method-card">
							<h5>{t("deliverymethod")}</h5>
							<select
								value={dergesaMenyra}
								onChange={(e) => setDergesaMenyra(e.target.value)}
								className="method-select"
							>
								<option value="Самоподигање">{t("pickup")}</option>
								<option value="Такси / Комби">{t("taksikombi")}</option>
								<option value="Бус">{t("bus")}</option>
								<option value="Достава">{t("delivery")}</option>
								<option value="Карго">{t("cargo")}</option>
							</select>
						</div>
					</div>

					{/* Total and Checkout */}
					<div className="checkout-section">
						<p className="total-price">
							{t("totalsuma")}: {totalShuma}
						</p>
						<button
							onClick={() => checkOut()} //alert("Proceeding to Checkout")}
							className="checkout-btn"
						>
							{t("checkout")}
						</button>
					</div>
				</>
			)}
		</div>
	);
}

export default Cart;
