import { React, useState, createContext, useContext, useEffect } from "react";
import {
	baseURL,
	dealerordersURL,
	initialCartURL,
	productsNameSearchURL,
} from "../services/ApiService";
import PropTypes from "prop-types";
import {
	addFirstDealerOrders,
	addNewItemAndUpdateCart,
	removeItemAndUpdateCart,
	updateItemAndUpdateCart,
} from "./CartContextFunctions";

import axiosInstance from "../services/axiosInstance";
//import useSSE from "../services/useSSE";
import { useAuthContext } from "./AuthContext";
import { useConfirmation } from "../components/confirmationdialog/customConfirmationDialog";
import { useRemoveConfirmation } from "../components/confirmationdialog/removeConfirmation";

const CartContext = createContext();

const useCartContext = () => {
	return useContext(CartContext);
};

const CartProvider = ({ children }) => {
	const { message, isConnected } = useAuthContext();
	console.log("SSE Message:", message);
	console.log("SSE Connected:", isConnected);

	const [results, setResults] = useState([]);
	const [query, setQuery] = useState("");
	const [totalShuma, setTotalShuma] = useState(0);

	const kerkimi = query.toUpperCase();
	//--------------pagesa menyra
	const initialPagesa = localStorage.getItem("pagesaoption") || "Кеш";
	const [pagesaMenyra, setPagesaMenyra] = useState(initialPagesa);
	const initialDergesa =
		localStorage.getItem("dergimioption") || "Самоподигање";
	const [dergesaMenyra, setDergesaMenyra] = useState(initialDergesa);
	//----------------------------

	const initialCartItems =
		JSON.parse(localStorage.getItem("updatedcartstorage")) || [];
	const [cartItems, setCartItems] = useState(initialCartItems);
	//const [cartItems, setCartItems] = useState([]);

	//const checkUserId = localStorage.getItem("customerid");
	const initialRemovedCartItems =
		JSON.parse(localStorage.getItem("removeditemsUpdated")) || [];
	const [cartItemsRemoved /*, setCartItemsRemoved*/] = useState(
		initialRemovedCartItems
	);
	const clientId = localStorage.getItem("customerid"); // Retrieve client ID from localStorage
	//const { message } = useSSE(clientId); // Use the SSE hook with the clientId
	//const sseMessage = localStorage.getItem("sseMessage");
	//---------------------replace special characters
	const kerkimipaster = kerkimi.replace(/[^A-Za-z0-9]/gi, "");
	console.log("kerkimi paster", kerkimipaster);
	//const [id_dealersorder, setIdDealersOrders] = useState(initialDealersOrdersId);

	async function handleSearchQuery() {
		//console.log(kerkimi);
		let encodedQuery = encodeURIComponent(kerkimipaster);
		try {
			const response = await axiosInstance.get(`${baseURL}${encodedQuery}`);
			if (response.data.length === 0) {
				setResults([]);
			} else {
				setResults(response.data);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			console.log(results);
			setResults([]);
		}
	}
	async function handleSearchNameQuery() {
		let encodedQuery = encodeURIComponent(kerkimi);
		//console.log(kerkimi);
		try {
			const response = await axiosInstance.get(
				`${productsNameSearchURL}${encodedQuery}`
			);
			if (response.data.length === 0) {
				setResults([]);
			} else {
				/*
				const transformedResults = response.data.map((item) => ({
					id: item.id,
					oem_nr: item.upcsku,
					produkt_name: item.productname,
					vend_nr: item.vendoritemnumber,
					brand: item.brand,
					cmimi_pakice: item.salesprice,
					cmimi_shumice: item.salesstandardcost,
					pershkrimi: item.salesdescription,
					purchase_description: item.purchasedescription,
					kampanja: item.kampanja,
					cmim_kampanje: item.cmim_kampanje,
					data_kampanja: item.data_kampanja,
					sasia: item.sasia,
					sasia_1: item.sasia_1,
					sasia_2: item.sasia_2,
				}));
				*/
				setResults(response.data);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			console.log(results);
			setResults([]);
		}
	}

	/*
	useEffect(() => {
		if (results.length === 0) {
			setResults([]);
		}
	}, [results, setResults]);
	*/
	const showConfirmation = useConfirmation();
	const handleAddToCartResult = async (result, manualQuantity = 1) => {
		// Retrieve the user token from local storage
		//	setIdDealersOrders(localStorage.getItem("dealersorders_id"))
		const token = localStorage.getItem("token");
		const id_dealersorder = localStorage.getItem("id_dealersorders");
		// Check if the user is logged in (i.e., token exists)
		console.log("cartitems length", cartItems.length);
		if (token) {
			// Find the index of an existing cart item that matches the provided item's `oem_nr` and `vend_nr`
			const itemIndex = cartItems.findIndex(
				(cartItem) =>
					cartItem.oem_nr === result.oem_nr &&
					cartItem.vend_nr === result.vend_nr
			);
			if (id_dealersorder) {
				console.log("id dealersorders exist: ", id_dealersorder);

				if (itemIndex !== -1) {
					const userResponse = await showConfirmation();

					if (!userResponse) {
						//alert("Duke dalur...");
						return; // Exit if the user selects "No"
					}

					// If the item already exists in the cart, add the manual quantity
					const updatedCart = [...cartItems];
					updatedCart[itemIndex].quantity += parseInt(manualQuantity, 10); // Increment quantity
					updatedCart[itemIndex].shuma =
						updatedCart[itemIndex].cmimi_shumice *
						updatedCart[itemIndex].quantity; // Update `shuma`
					setCartItems(updatedCart); // Update the cart
					console.log(
						`If the item already exists ID: ${updatedCart[itemIndex].id},quantity: ${updatedCart[itemIndex].quantity}`
					); // Log the product ID

					const idToUpdate = updatedCart[itemIndex].id;
					const quantityToUpdate = updatedCart[itemIndex].quantity;
					updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
				} else {
					// If the item is not in the cart, add it with the manual quantity
					const quantityToAdd = parseInt(manualQuantity, 10) || 1; // Use manualQuantity or default to 1
					const newItem = {
						...result, // Add the new item details
						produkt_name: result.produkt_name,
						quantity: quantityToAdd, // Set the provided or default quantity
						quantity_from_depot: quantityToAdd,
						quantity_sent: quantityToAdd,
						total: result.cmimi_shumice * quantityToAdd,
						id_product: result.id,
						price: result.cmimi_shumice,
						id_dealersorders: id_dealersorder, // Calculate `shuma` based on quantity
					};
					setCartItems((prevCartItems) => [...prevCartItems, newItem]);
					/*
					addNewItemAndUpdateCart(newItem);
					const response = addNewItemAndUpdateCart(newItem);
					console.log('Result Handle add to cart', response)
					*/
					try {
						const response = await addNewItemAndUpdateCart(newItem);
						const modresult = response.map((item) => ({
							produkt_name: item.productname,
							quantity: item.quantity,
							quantity_from_depot: item.quantity,
							quantity_sent: item.quantity,
							total: item.total,
							id_product: item.id_product,
							price: item.price,
							id_dealersorders: item.id_dealersorders,
						}));
						console.log("Result Handle add to cart:", modresult);
					} catch (error) {
						console.error("Error while adding new item:", error);
					}
					console.log(
						`IdDealers Exist and If the item is not in the cart ID: ${result.id},quantity: ${newItem.quantity}`
					); // Log the product ID
				}
			} else {
				console.log("id dealers order does not exist");
				const iddealersorders = await addFirstDealerOrders();
				console.log("return from iddealersOrders Function", iddealersorders);
				// The item is not in the cart, add it with the manual quantity
				const quantityToAdd = parseInt(manualQuantity, 10) || 1; // Use manualQuantity or default to 1
				const newItem = {
					produkt_name: result.produkt_name,
					quantity: quantityToAdd, // Set the provided or default quantity
					quantity_from_depot: quantityToAdd,
					quantity_sent: quantityToAdd,
					total: result.cmimi_shumice * quantityToAdd,
					id_product: result.id,
					price: result.cmimi_shumice,
					id_dealersorders: id_dealersorder,
				};
				setCartItems((prevCartItems) => [...prevCartItems, newItem]);
				console.log("New Item without ID", newItem);

				try {
					const response = await addNewItemAndUpdateCart(newItem);
					const modresult = response.map((item) => ({
						produkt_name: item.productname,
						quantity: item.quantity,
						quantity_from_depot: item.quantity,
						quantity_sent: item.quantity,
						total: item.total,
						id_product: item.id_product,
						price: item.price,
						id_dealersorders: item.id_dealersorders,
					}));
					console.log("Result Handle add to cart (first item):", modresult);
					console.log(
						`IdDealers DOES NOT Exist and If the item is not in the cart ID: ${result.id}, quantity: ${newItem.quantity}`
					); // Log the product ID
				} catch (error) {
					console.error("Error while adding first item:", error);
				}
			}
		} else {
			// If the user is not logged in, prompt them to log in
			alert("Please log in first.");
		}
	};

	const handleAddManualQuantity = (index, quantity) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity = parseInt(quantity, 10) || 0;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
		setCartItems(updatedCart);
		console.log("hnadleAddManualQuantity ID: ", updatedCart[index].id);
	};
	const handleIncrementQuantity = (index) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity += 1;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
		setCartItems(updatedCart);
		console.log(
			`"handleIncrementQuantity ID: ", ${updatedCart[index].id} quantity: ${updatedCart[index].quantity}`
		);
	};

	const handleDecrementQuantity = (index) => {
		const updatedCart = [...cartItems];

		// Prevent quantity from going below 1
		if (updatedCart[index].quantity === 1) {
			console.log("Quantity cannot be less than 1");
			return; // Exit the function early
		}

		updatedCart[index].quantity -= 1;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;

		if (updatedCart[index].quantity === 0) {
			updatedCart.splice(index, 1);
		}

		setCartItems(updatedCart);
		console.log(
			`"handleDecrementQuantity ID: ", ${updatedCart[index].id} quantity: ${updatedCart[index].quantity}`
		);
	};
	/*
	const handleAddToCartItem = (itemIndex) => {
		const updatedCart = [...cartItems];
		updatedCart[itemIndex].quantity += 1;
		updatedCart[itemIndex].shuma =
			updatedCart[itemIndex].cmimi_shumice * updatedCart[itemIndex].quantity;
		setCartItems(updatedCart);
	};
*/
	const showConfirmationRemove = useRemoveConfirmation();
	const handleRemoveItem = async (index) => {
		const updatedCart = [...cartItems];
		//const itemIndex = updatedCart.findIndex(index => index.id === id);

		if (index !== -1) {
			const userResponse = await showConfirmationRemove();

			if (!userResponse) {
				//alert("Duke dalur...");
				return; // Exit if the user selects "No"
			}
			updatedCart[index].quantity = 0;
			console.log("updated cart before:", updatedCart.length);
			console.log("index id?: ", updatedCart[index].id);

			if (updatedCart[index].quantity === 0) {
				removeItemAndUpdateCart(updatedCart[index].id);
				updatedCart.splice(index, 1);

				//console.log("Removed item id:", updatedCart[index].id);
			}

			if (updatedCart.length === 0) {
				localStorage.removeItem("updatedcartstorage");
				setCartItems([]);
			} else {
				setCartItems(updatedCart);
			}
		} else {
			console.log("Item with id not found:", updatedCart[index].id);
		}
	};

	/*

	// 1. Optimized useEffect for cartItems update and localStorage
	useEffect(() => {
		if (cartItems.length > 0) {
			// Create the updatedItems array with calculations
			const updatedItems = cartItems.map((item) => ({
				produkt_name: item.produkt_name,
				//oem_nr: item.oem_nr,
				//vend_nr: item.vend_nr,
				emri: item.produkt_name,
				id_product: item.id_product,
				price: item.price,
				quantity: item.quantity,
				quantity_sent: item.quantity_sent || item.quantity,
				quantity_from_depot: item.quantity_from_depot || item.quantity,
				//id: item.id,
				total: item.price * item.quantity,
			}));

			// Calculate totalShuma directly
			const calculateTotalShuma = cartItems.reduce(
				(total, item) => total + item.price * item.quantity,
				0
			);

			// Update state and localStorage
			setTotalShuma(calculateTotalShuma);
			//localStorage.setItem("updatedcartstorage", JSON.stringify(updatedItems));

			console.log(
				"Updated cart storage and totalShuma:",
				updatedItems,
				calculateTotalShuma
			);
		}
	}, [cartItems]); // Only depend on cartItems
*/
	// 2. Combined useEffect for SSE connection and handling message updates

	// 3. Optimized useEffect for fetching updated cart from server
	useEffect(() => {
		const token = localStorage.getItem("token");
		if (!token) return;
		const fetchCart = async () => {
			const id_dealersorder = localStorage.getItem("customerid");
			const dealersordersid = localStorage.getItem("id_dealersorders");
			console.log("No ID Dealers order found duhet te vazhdoje");
			console.log("Customer ID: ", id_dealersorder);
			if (!dealersordersid) {
				console.log("No ID Dealers order found");
				const getdealersOrdersId = `${dealerordersURL}/-1/${id_dealersorder}/0/-1/-1`;

				await axiosInstance
					.get(getdealersOrdersId)
					.then(function (response) {
						if (response.data && response.data.length > 0) {
							const dealerOrdersId = response.data[0].id;
							localStorage.setItem("id_dealersorders", dealerOrdersId);
						} else {
							console.log("No data available. Continuing...");
						}
					})
					.catch(function (error) {
						console.error("Error fetching data:", error);
					});
				console.warn("No customer ID found in localStorage.");
			}

			const urlHistory = `${initialCartURL}${id_dealersorder}/0`;
			console.log("Fetching updated cart from server:", urlHistory);

			await axiosInstance
				.get(urlHistory)
				.then((response) => {
					const lastOrderResponse = response.data.map((item) => ({
						produkt_name: item.productname,
						oem_nr: item.upcsku,
						vend_nr: item.vendoritemnumber,
						emri: item.productname,
						id_product: item.id_product,
						price: item.price,
						quantity: item.quantity,
						quantity_sent: item.quantity_sent || item.quantity,
						quantity_from_depot: item.quantity_from_depot || item.quantity,
						id: item.id,
						//id_dealersorder:item.id_dealersorder,
						total: item.price * item.quantity,
					}));

					const calculateTotalShuma = lastOrderResponse.reduce(
						(total, item) => total + item.price * item.quantity,
						0
					);

					setTotalShuma(calculateTotalShuma);
					setCartItems(lastOrderResponse || []);
					localStorage.setItem(
						"updatedcartstorage",
						JSON.stringify(lastOrderResponse)
					);
					//	localStorage.setItem("id_dealersorders",lastOrderResponse.id_dealersorder[0] )

					console.log("Updated cart fetched from server:", lastOrderResponse);
				})
				.catch((error) => {
					console.error("Error fetching updated cart:", error);
				});
		};

		if (message === "No database instance") {
			console.log("No database instance: checking cart every 5 seconds.");

			// Run immediately
			fetchCart();

			// Set interval to fetch cart every 5 seconds
			const intervalId = setInterval(fetchCart, 5000);

			return () => clearInterval(intervalId);
		} else if (message === "Table order updated") {
			console.log("Table order updated: Fetching cart...");
			localStorage.removeItem("id_dealersorders");
			//setCartItems([])
			fetchCart();
		} else {
			if (message) {
				console.log("Connected to SSE server! SSE message from localStorage");
				localStorage.setItem("header id from SSE Server", clientId);
			} else {
				console.log("Attempting to reconnect...");
			}
			console.log("Message updated from server");
			fetchCart();
		}
	}, [message]);

	//--------------------------------

	//-------------------------------------------------------------------------
	//console.log('query search',query)
	//console.log('initial cart items cartcontext??',initialCartItems)
	return (
		<CartContext.Provider
			value={{
				results,
				//handleAddToCartItem,
				handleAddToCartResult,
				cartItems,
				setCartItems,
				query,
				setQuery,
				handleSearchQuery,
				handleIncrementQuantity,
				handleDecrementQuantity,
				totalShuma,
				setTotalShuma,
				cartItemsRemoved,
				pagesaMenyra,
				setPagesaMenyra,
				dergesaMenyra,
				setDergesaMenyra,
				handleAddManualQuantity,
				handleRemoveItem,
				handleSearchNameQuery,
				setResults,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};
// Add prop validation for the 'children' prop
CartProvider.propTypes = {
	children: PropTypes.node,
};

export { CartProvider, CartContext, useCartContext };
