import React, { useState, useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCartContext } from "../../contexts/CartContext";
import Cart from "../../pages/right/shopingcart/ShopingCart"; // Adjust the import path as needed
import { useAuthContext } from "../../contexts/AuthContext";
//import useSSE from "../../services/useSSE";
import "./IconBar.css";
import ChatComponent from "../topbar/chat/ChatComponent";
import {  Route, useNavigate } from "react-router-dom";
import ShopingCartPage from '../../pages/ShopingCartPage';

function IconBar() {
	const { cartItems } = useCartContext();
	const [showCart, setShowCart] = useState(false);
	const [isNewMessage, setIsNewMessage] = useState(false); // State for chat notifications
	const [chatIcon, setChatIcon] = useState("/image/icons/chat.svg"); // Default chat icon
	const { Logout } = useAuthContext();
	const clientId = localStorage.getItem("customerid");
	const companyname = localStorage.getItem("companyname");
	//const { resetConnection } = useSSE(clientId);

	// Calculate total quantity of items in the cart
	const totalQuantity = cartItems.reduce(
		(total, item) => total + item.quantity,
		0
	);
	//handle double click at dropdown cart menu


	// Check for new messages using localStorage
	const checkForNewMessages = () => {
		const lastMessage = JSON.parse(localStorage.getItem("lastMessage")) || {};
		const clientMessages =
			JSON.parse(localStorage.getItem("recievedmessage")) || {};

		if (JSON.stringify(lastMessage) !== JSON.stringify(clientMessages)) {
			setIsNewMessage(true);
			setChatIcon("/image/icons/newchat.svg"); // Change to new message icon
		} else {
			setIsNewMessage(false);
			setChatIcon("/image/icons/chat.svg"); // Reset to default icon
		}
	};

	useEffect(() => {
		// Check for new messages every 30 seconds
		const intervalId = setInterval(checkForNewMessages, 3000);
		return () => clearInterval(intervalId); // Cleanup on unmount
	}, []);

	const handleChatClick = () => {
		setIsNewMessage(false);
		setChatIcon("/image/icons/chat.svg"); // Reset the icon
		localStorage.setItem(
			"lastMessage",
			JSON.stringify(JSON.parse(localStorage.getItem("recievedmessage")))
		); // Sync lastMessage with clientmessages
	};
	const navigate = useNavigate();

	return (
		<Container className="d-flex align-items-center">
			<h5 className="comapanyname-icons">{companyname}</h5>

			{/* Cart Dropdown */}
			<Dropdown
				align="end"
				show={showCart}
				onToggle={(isOpen) => setShowCart(isOpen)}
			>
				<Dropdown.Toggle
					as="div"
					onClick={() => navigate('/cart')} // Works anywhere in the app

					style={{
						cursor: "pointer",
						position: "relative",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img
						src="/image/icons/cart.svg"
						alt="Cart"
						style={{
							width: "24px",
							height: "24px",
							marginRight: "25px",
						}}
					/>
					{totalQuantity > 0 && (
						<span
							style={{
								position: "absolute",
								top: "-8px",
								right: "7px",
								backgroundColor: "red",
								color: "white",
								borderRadius: "50%",
								padding: "4px 8px",
								fontSize: "12px",
								lineHeight: "1",
								textAlign: "center",
							}}
						>
							{totalQuantity}
						</span>
					)}
				</Dropdown.Toggle>
				<Dropdown.Menu
					style={{ width: "400px", maxHeight: "500px", overflowY: "auto" }}
				>
					<Cart />
				</Dropdown.Menu>
			</Dropdown>

			{/* Chat Dropdown */}
			<Dropdown align="end">
				<Dropdown.Toggle
					as="div"
					style={{
						cursor: "pointer",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img
						src={chatIcon} // Dynamic chat icon
						alt="Chat"
						style={{
							width: "24px",
							height: "24px",
							marginLeft: "5px",
							marginRight: "25px",
						}}
						onClick={handleChatClick}
					/>
				</Dropdown.Toggle>
				<Dropdown.Menu
					style={{ width: "400px", maxHeight: "620px", overflowY: "auto" }}
				>
					<ChatComponent />
				</Dropdown.Menu>
			</Dropdown>

			{/* Logout Button */}
			<img
				src="/image/icons/logout.svg"
				alt="Exit"
				style={{
					width: "24px",
					height: "24px",
					marginRight: "5px",
				}}
				onClick={() => {
					Logout();
					localStorage.clear();
					// resetConnection();
					// Example: Redirect to login page
					// window.location.href = "/login";
				}}
			/>
		</Container>
	);
}

export default IconBar;
