import React, { useState, useEffect } from "react";
import "./GoToTopButton.css"; // Import the CSS file

const GoToTopButton = () => {
	const [isVisible, setIsVisible] = useState(false);

	// Show button when scrolled down 200px, hide when back at the top
	const checkScrollTop = () => {
		if (!isVisible && window.pageYOffset > 200) {
			setIsVisible(true);
		} else if (isVisible && window.pageYOffset <= 200) {
			setIsVisible(false);
		}
	};

	// Scroll to top function
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScrollTop);
		return () => {
			window.removeEventListener("scroll", checkScrollTop);
		};
	}, [isVisible]);

	return (
		<img
			onClick={scrollToTop}
			className={`go-to-top-button ${isVisible ? "visible" : "hidden"}`}
			src="./image/gototop.png" // Replace this with the path to your image
			alt="Go to Top"
		/>
	);
};

export default GoToTopButton;
