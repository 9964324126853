import { useEffect, useState } from "react";
import GoToTopButton from "./components/gototop/goToTopButton";
import SignOutInactiveUser from "./components/inactivitycheck/checkinactivity";
import NavigationTop from "./navigation/Navigation";
import useVersionChecker from "./components/versionNew/versionChecker";
import Footer from "./pages/footer/footer";
import TopFooter from "./navigation/topbar/topFooter";
import { BrowserRouter as Router } from "react-router-dom";

export default function App() {
  const defaultTitle = "Autoparts B2B";
  const [appName, setAppName] = useState(defaultTitle);
  const [faviconNew, setFavIconNew] = useState("favicon-default.ico");

  useEffect(() => {
    const storedAppName = localStorage.getItem("firmaemri");
    if (storedAppName) {
      setAppName(`${storedAppName} B2B`);
      setFavIconNew(`favicon-${storedAppName.toLowerCase()}.ico`);
    } else {
      setAppName(defaultTitle);
      setFavIconNew("favicon-default.ico");
    }
  }, []);

  useEffect(() => {
    document.title = appName;

    function setFavicon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = `/${faviconNew}?v=${Date.now()}`; // Prevent favicon caching
    }

    setFavicon();
  }, [appName, faviconNew]);

  //---------------service worker for new version----------
  useVersionChecker();
//-------------------------------------------------------------- pp


  return (
    <>
    <Router>
    <TopFooter/>
      <SignOutInactiveUser />
      <NavigationTop />
      <GoToTopButton />
      <Footer/>
      </Router>
    </>
  );
}
